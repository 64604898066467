*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
body{
  height: 100vh;
  max-width: 1920px;
  margin: auto;
  width: 100%;
  overflow-x: hidden;
}
.flynautCnt{
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-decoration: none;
}
.flynautCnt img{
  height: 50%;
}
.flynautCnt p{
  font-size: 14.5px;
  color: gray;
}
.partnerTxt{
  font-size: 12px !important;
}
#main{
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: #F8FAFB;
  display: flex;
  flex-direction: column;
}

#header{
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.outputlogo{
  width: 150px !important;
  margin: 0 10px;
}
#header .pcLinks{
  margin-right: 30px;
  display: flex;
}
#header .pcLinks ul{
  list-style-type: none;
  display: none;
  align-items: center;
}
#header .pcLinks ul li{
  margin: 0 10px;
  font-size: 16px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

#header .pcLinks .logoutBtn{
  width: 28px;
  margin: 0 0 0 25px;
  cursor: pointer;
}

#header .pcLinks .active::after{
  content: '';
  position: absolute;
  height: 6px;
  width: 100%;
  left: 0;
  bottom: -28px;
  background-color: #24336D;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#header .menuBtn{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  padding: 0;
}
#header .menuBtn svg{
  font-size: 30px;
}



/* *************************** */

#mobMenuCnt{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  background-color: rgba(0, 0, 0, .2);
}
#mobMenuPanel{
  width: 260px;
  height: 100vh;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0 0 10px gray;
  padding: 10px;
  transform: translateX(-100%);
  transition: all 500ms ease;
}
.mobMenuPanelTop{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  align-items: center;
}
.mobMenuPanelTop .logoutBtn{
  cursor: pointer;
}
.mobMenuItem{
  width: 100%;
  height: 40px;
  margin: 5px 0;
  border: 1px solid gainsboro;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.mobItemActive{
  background-color: #24336D;
  color: white;
}

#mobMenuClose{
  flex: 1;
  height: 100%;
}
.mobmenulogo{
  width: 150px;
}



/***********************************************/

.barCnt{
  width: auto;
  height: auto;
  background: transparent;
  padding-top: 2px;
  display: flex;
  align-items: center;
}
.bcTabs{
  display: flex;
}
.tab{
  height: 40px;
  min-width: fit-content;
  max-width: auto;
}
.deactiveTab{
  background-color: #F8FAFB;
  color: black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  cursor: pointer;
  position: relative;
  padding: 0 10px;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  /* #6d597a */
}

.activeTab{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #273b8b;
  color: white;
  border-radius: 10px;
  padding: 0 10px;
  transition: all 300ms ease-in-out;
  border-bottom-color: transparent;
}
.tabName{
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;
}

.tabCloseBtn{
  margin-left: 5px;
  font-size: 12px;
  padding: 2px;
  cursor: pointer;
}

.tabCloseBtn:hover{
  background: rgba(0, 0, 0, .3);
}

.bcAdd{
  margin: 0 15px 0 0;
  cursor: pointer;
}
.calcScreenCnt{
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.calcScreen{
  flex: 1;
  width: 100%;
}

.outputScreen{
  animation: reveal 500ms ease;
}
.chartBoxCnt{
  animation: slideLeft 1s cubic-bezier(.59,.43,.32,1.32);
}

.calcScreen{
  animation: enlarge 300ms cubic-bezier(.17,.67,.83,.67);
}
/***********************************************/
.inputCnt{
  background-color: #EDEDED;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 49px;
  padding: 0 15px;
  margin: 10px 0;
}
.mainInput{
  flex: 1;
  margin: 0 0 0 0;
}
.mainInput,
.mainInput:focus,
.mainInput input, 
.mainInput input:focus, 
.mainInput input:active,
.mainInput .ant-select-selector,
.mainInput .ant-select-selection,
.mainInput .ant-select-selection-search-input,
.mainInput .ant-select-selection-search{
  border: none !important;
  background: red !important;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.mainInput::placeholder, .mainInput input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #656A72;
  opacity: 1; /* Firefox */
}

.mainInput::-ms-input-placeholder, .mainInput input::-ms-input-placeholder { /* Microsoft Edge */
  color: #656A72;
}

.error{
  border-bottom: 3px solid red;
  /* background: white !important; */
}

.btn{
  height: 49px;
  border-radius: 25px;
  background-color: #24336D;
  color: white;
  width: 100%;
}

.otpInputStyle{
  width:24% !important; 
  height:49px;
  background:#EDEDED;
  border-radius:50px;
  border:none;
  appearance: text; margin: 0 5px;
  -moz-appearance:textfield;
}

.otpInputStyle::-webkit-outer-spin-button,
.otpInputStyle::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formMainCnt{
  margin: 0 0 25px 0;
  width: 100%;
}
.formTitle{
  font-size: 14px;
}
.formInputCnt{
  background-color: #EDEDED;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 49px;
  padding: 0 15px;
  margin: 10px 0;
}

.formQuestion{
  margin: 0 0 0 5px;
  cursor: pointer;
}
.formMsg{
  border: 1px solid #E6E6E6;
  border-radius: 13px;
  padding: 15px 10px;
  width: auto;
}
.formMsg p{
  color: #868686;
  font-size: 13px;
  margin-bottom: 15px;
}
.formMsg p>span{
  color: #24336D;
  
}


.modal{
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.modalPopUp, .modalPopUpGrid{
  background-color: white;
  border-radius: 25px;
  padding: 45px 15px 35px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20vh 15px 0 15px;
  width: auto;
  min-height: 200px;
  height: auto;
  position: relative;
  overflow: visible !important;
}

.mpuTop{
  display: flex;
  justify-content: space-between;
}
.mpuMid{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0 0 0;
  padding: 15px 15px 0 15px;
  border-top: 1px solid gainsboro;
}
.mpugTop{
  padding: 45px 35px 35px 35px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 35px;
  row-gap: 30px;
}

.mpuTop>div{
  flex: 1;
  margin: 0 25px;
}
.mpuBot,.mpugBot{
  margin: 15px 0 0 0;
  display: flex;
  justify-content: center;
}
.popupClose{
  border:none;
  box-shadow: 0px 3px 6px #00000029;
  padding:7px;
  border-radius: 50%;
  color: #28303F;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  transform: translate(5px,-5px);
}



.mybtn-blue{
  border-radius: 50px;
  padding: 15px 70px;
  background:#24336D;
  height: auto;
  color: white;
  font-size: 12px !important;
}
.histbtn-blue{
  border-radius: 10px !important;
  padding: 10px 30px !important;
  background:#6d597a !important;
  height: auto !important;
  color: white;
  font-size: 12px !important;
}
.histbtn-blue:hover{
  background:#543f62 !important;
  color: white !important;
}

.histbtn-blue:disabled{
  opacity: .5 !important;
  background:#273b8b !important;
  color: white !important;
}
.bk-blue{
  background:#273b8b !important;
}
.bk-blue:hover{
  background:#273b8b !important;
  opacity: .8 !important;
}

.histbtn-gray{
  border-radius: 50px !important;
  padding: 15px 40px !important;
  background: white !important;
  height: auto !important;
  color: black;
  font-size: 12px !important;
}


.cavityPopCnt{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cavityPopCnt>div{
  margin: 20px auto;
  padding: 25px 35px;
  border-radius: 25px;
}
.label{
  font-size: 14px;
  margin: 0 0 3px 0;
  display: block;
}


.myTableCnt{
  width: 100%;
  height: auto;
  overflow-x: scroll;
  padding: 0 0 15px 0;
}
.myTable{
  width: 100%;
  min-width: 400px;
  max-width: 800px;
  height: auto;
}
.myTable th{
  padding: 15px;
  background: #F0F0F0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.myTable td{
  padding: 15px 5px;
  font-weight: 16px;
  text-align: center;
}

.myTable tbody tr{
  background: white;
  font-size: 14px;
  font-weight: normal;
}

.myTable tbody tr:hover{
  background: #F0F0F0;
}


.bottomBarCnt{
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 15;
  background-color: #F8FAFB;
  border-top: 1px solid lightgray;
  padding-bottom: 2px;
}
.barScrollCnt{
  flex:1;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  margin: auto 0;

}
.barScrollCollapsed{
  flex: unset;
}
.bottomTab{
  border: none;
  margin: auto 1px;
  height: 35px;
  width: auto;
  min-width: fit-content;
  display: flex;
  align-items: center;
  transition: all 500ms ease-in-out;
  padding: 0 10px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
}
.btActive{
  background-color: #6d597a;
  width: auto;
  color: whitesmoke;
}
.btDeactive{
  background-color: transparent;
  width: 65px;
}
.bottomTabSeperator{
  margin: 0 10px;
  font-size: 20px;
  transform: rotate(90deg);
}
.addScenarioBtn{
  cursor:pointer;
  color: black;
  margin: 0;
}

.jobBar{
  position: fixed;
  bottom: 38px;
  left: 0;
  width: 100%;
  height: auto;
  border-top: 1px solid lightgray;
  z-index: 15;
  background-color: #F8FAFB;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2px;
}
.jobTab{
  border: none;
  margin: 0 1px;
  height: 27px;
  min-width: fit-content;
  display: flex;
  align-items: center;
  transition: all 300ms ease-in-out;
  padding: 0 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}
.jobTab:nth-child(odd){
  animation: slideUp 300ms cubic-bezier(.17,.67,.83,.67);
}
.jobTab:nth-child(even){
  animation: slideUp 500ms ease;
}

.calculateBtnCnt{
  position: fixed;
  right: 0;
  bottom: 69px;
  display: flex;
  align-items: center;
  background-color: white;
}
.calculateBtn{
  font-size: 15px;
  background: #24336D;
  color: whitesmoke;
  height: 38px;
}
.calculateBtnActive{
  background: #9C27B0;
}
.calculateBtn:hover{
  background: #273b8b;
}
.calculateSettingBtn{
  margin: 0 10px 0 5px;
  cursor: pointer;
  transform: scale(1.3);
}
.calculateSettingBtn:hover{
  transition: all 300ms ease;
  transform: scale(1.1)rotate(25deg);
}
.calculateSettingBtnActive{
  color: #6d597a;
  animation: rotate 5s linear infinite;
}
@keyframes rotate{
  0%{
    transform: rotate(0deg)scale(1.3);
  }
  100%{
    transform: rotate(360deg)scale(1.3);
  }
}

@keyframes slideUp{
  0%{
    transform: translateY(80%);
  }
  100%{
    transform: translateY(0);
  }
}
.jobTabActive{
  background-color: #d8315b;
  color: whitesmoke;
}
.jobTabDeactive{
  background-color: transparent;
}

.barScrollCnt::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

#footer{
  background-color: #24336D;
  color: white;
  padding: 10px 0;
  text-align: center;
  font-size: 10px;
}
.popupMenuCnt{
  height: auto;
  width: 200px;
  box-shadow: 0 0 10px -2px rgba(69, 67, 67, 0.485);
  position: fixed;
  z-index: 100;
  background-color: white;
  border-radius: 10px;
}
.popupMenuCnt ul{
  list-style-type: none;
  padding: 15px;
}
.popupMenuCnt ul li{
  border-bottom: 1px solid lightgray;
  padding: 5px;
  cursor: pointer;
  color: #43464b;
  transition: all 300ms ease-in-out;
}
.popupMenuCnt ul li:hover{
  transform: scale(0.9);
}



.currencyIcon{
  color: #656A72;
  height: 12px !important;
  width: 12px !important;

}
.currencyIcon *{
  fill: #656A72;
}
.percentIcon{
  color: #656A72;
  font-size: 14px !important;
  margin: 0 5px 0 0;
}
.myselect{
  padding: 0 0 0 11px;
}


.outputScreen{
  width: 100%;
  min-height: 110vh;
  height: auto;
  position: absolute;
  background-color: #F8FAFB;
  /* padding: 40px 0; */
  top: 0;
  left: 0;
  z-index: 16;
}

.danger{
  background-color: rgb(255, 51, 0) !important;
  color: white;
}
.danger *{
  color: white;
  fill: white;
}
.superdanger{
  background-color: rgb(150, 25, 25) !important;
  color: white;
}
.superdanger *{
  color: white;
  fill: white;
}
.notpossiblevalue{
  background-color: rgb(12, 12, 12) !important;
  color: white;
}
.notpossiblevalue *{
  color: white;
  fill: white;
}
.green{
  background-color: rgb(24, 170, 24) !important;
  color: white;
}
.green *{
  color: white;
  fill: white;
}

.doublePopupempty{
  background-color: rgb(212, 44, 44) !important;
}
.doublePopupempty *{
  color: whitesmoke !important;
}
.doublePopupfilled{
  background-color: rgb(166, 255, 111) !important;
}

.chartContainer{
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chartBoxCnt{
  width: 90%;
  min-height: 100vh;
  margin: 50px auto 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

}
.chartBox{
  margin: 0 50px;
  width: 100%;
  min-width: 800px;
  height: auto;
  overflow-y: hidden;
  background-color: white;

}
.graphTitle{
  margin: 0 0 10px 0;
  text-align: center;
}

.signs{
  margin: 0 5px;
}

.modalBox{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalInner{
  width: 80%;
  min-height: 100px;
  border-radius: 25px;
  box-shadow: 0 0 10px -2px rgba(141, 141, 141, 0.2);
  background-color: white;
  max-width: 400px;
  overflow: hidden;
}
.modalHeader{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #6d597a;
}
.modalHeader p{
  color: whitesmoke;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}
.modalContent{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 20px;
  color: #43464b;
  max-width: 80%;
  font-size: 16px;
}
.modalBtnCnt{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 20px;
  border-top: 1px solid gainsboro;
}
.modalBtn{
  width: 80px;
  height: 45px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #6d597a;
  color: #6d597a;
  background: white;
  margin: 0 0 0 5px;
}
.modalBtn:hover{
  background: rgb(241, 241, 241);
}
.modalBtn:active{
  opacity: 0.7;
}

.ant-select-selection-overflow-item{
  border-radius: 25px !important;
  overflow: hidden;
  background-color: white;
  margin: 5px 3px;
}
.ant-select-selection-item{
  background-color: white !important;
}
.ant-select-selection-overflow-item-suffix{
  background-color: transparent !important;
}

.loaderTxt{
  font-size: 24px;
  text-align: center;
  margin: 0 15px;
}

@media screen and (min-width:800px){
 

  .modalCnt{
    width: 90%;
  }
  .cavityPopCnt{
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .outputScreen{
    padding: 40px 0 0 0;
  }
  .mpugTop{
    grid-template-columns: 1fr 1fr;

  }
  .loaderTxt{
    font-size: 42px;
    text-align: center;
  }
}

@media screen and (min-width:1000px){
  #header .pcLinks ul{
    display: flex;
  }
  #mobMenuCnt{
    display: none;
  }
  #header .menuBtn{
    display: none;
  }

  #header> .logo{
    margin-left: 60px;
  }
  #header .pcLinks{
    margin-right: 60px;
  }
  /* .chartContainer{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 15px;
    column-gap: 15px;
  } */
}

@keyframes reveal{
  0%{
    transform: translateY(10%);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes enlarge{
  0%{
    transform: scale(.95);
    opacity: 0;
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes slideLeft{
  0%{
    transform: translateX(100%);
    /* opacity: 0; */
  }
  100%{
    transform: translateX(0);
    /* opacity: 1; */
  }
}

.ant-timeline-item-tail{
  display: none;
}

@media print{
  body{
    visibility: hidden;
  }
  .printable{
    visibility: visible;
  }
  .non-printable{
    display: none !important;
  }
}

.unveri,.veri,.blocked,.deleted{
  border-radius: 15px;
  padding: 5px;
  color: white;
  font-size: 12px !important
}
.unveri{
  background-color: yellowgreen;
}
.veri{
  background-color: #18aa18;
}
.blocked{
  background-color: #ff3300;
}
.deleted{
  background-color: #ff3300;
}

.guideLineCnt{
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

}

.selecHolder{
  height: auto;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.selecHolder svg{
  height: 22px;
  width: 22px;
  min-width: 22px;
  min-height: 22px;
}

.CCurrencyHolder{
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CCurrencyHolder svg{
  height: 100%;
  width: 100%;
}

.hoveredRow:hover{
  background-color: #eeeeee;
}
.authUserDisplayCnt{
  border-radius: 100px;
  height: 38px;
  width: auto;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #24336D;
  color: white;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  margin: 0 0 0 15px;
}
.authUserDisplayTop{
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.authUserDisplayTop>p{
  font-size: 10px;
  text-align: center;
}
.authUserDisplayBot{
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
  width: 200px;
  background-color: white;
  transform: translateY(48px);
  padding: 10px;
  box-shadow: 0 0 10px -3px gray;
  border-radius: 5px;
  z-index: 1;
}
.authUserDisplayBotTile{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  margin: 5px 0;
  border: 1px solid gainsboro;
}
.logoutRedBtn{
  background-color: #d11717;
  color: white;
  border: none;
}


@media screen and (min-width:1000px){
  .authUserDisplayCnt{
    padding: 0 25px;
  }
  .authUserDisplayTop>p{
    font-size: 14px;
  }
}



.noLoanDataCnt{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 50px;
  background: white;
  text-align: center;
  border-radius: 15px;
  margin: 15px;
  box-shadow: 0 0 10px -4px gray;
  gap: 10px;
  color: gray;
  flex-direction: column;
}
.noLoanDataCnt svg{
  font-size: 55px;
}


@media screen and (min-width:1000px){
  /* .noLoanDataCnt{
    flex-direction: row;
  } */
  .noLoanDataCnt svg{
    font-size: 75px;
  }
}


.mainLoanContainer{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 50px auto;
  max-width: 1250px;
}
.mainLoanContainer >div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.loanCatCount{
  border-radius: 20px;
  height: 20px;
  width: 20px;
  background-color: rgba(255, 0, 0, 0.7);
  border: 2px solid red;
  color: white;
  display : inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  padding: 2px;
  font-weight: bold;
}
.loanScenarioHeader{
  background : #6d597a;
  color:whitesmoke;
  padding:15px 5px;
  border-radius:5px;
}
.loanJobHeader{
  background : #d8315b;
  color:whitesmoke;
  padding:8px 5px 8px 5px;
  border-radius:5px;
  margin: 25px 0 0 15px;
}
.loanInnerHeader{
  background:#3777BC;
  padding:5px 5px 5px 5px;
  color:whitesmoke;
  margin-left: 30px;
}
.loanContainer{
  display: flex;
  overflow-x: scroll;
  gap: 5;
  margin-left: 30px;
}


@media screen and (min-width:1200px){

  .mainLoanContainer >div{
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
  }
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #0d0ddd transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #0d0ddd;
  width: 12px;
}


.bBottom{
  border-bottom: 1px solid gainsboro;
}
.outputScreenInput{
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,1);
  color: black;
  outline: none;
  border: 2px solid gainsboro;
  border-radius: 20px;
  text-align: center;
  box-sizing: border-box;
}