.scrollWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 50px 0;
}
.scrollWrapperInner{
    flex: 1;
    overflow-x: scroll;
    margin: 0 10px;
    display: flex;
}
.scrollWrapperContentHolder{
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.arrowBtn{
    background-color: #6d597a;
    color: white;
    height: 50px;
    width: 50px;
    font-size: 18px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 10px -2px gray;
    display: none;
}
.arrowBtn svg{
    width: 40%;
    height: auto;
}

@media screen and (min-width: 800px){
    .arrowBtn{
        display: flex;
    }
}