.container{
    max-height: 250px;
    width: 75%;
    height: auto;
    max-width: 350px;
    background-color: white;
    overflow-y: scroll;
    position: fixed;
    outline: none;
    border-radius: 15px;
    padding: 15px;
    scrollbar-width: 10px;
    scrollbar-color: #d8315b transparent;
    box-sizing: border-box;
}

.container h4 {
    margin-bottom: 10px;
}

.container p {
    font-size: 14px;
    color: rgb(134, 134, 134);
    line-height: 19px;
    margin-bottom: 15px;
}
.container ul{
    padding: 0 0 0 20px;
    margin: 15px 0;
}
.container li {
    font-size: 14px;
    color: rgb(134, 134, 134);
    line-height: 19px;
    margin-bottom: 10px;
}

  
/* Works on Chrome, Edge, and Safari */
.container::-webkit-scrollbar {
    width: 10px;
}

.container::-webkit-scrollbar-track {
    background: transparent;
}

.container::-webkit-scrollbar-thumb {
    background-color: #d8315b;
    width: 10px;
}


@media screen and (min-width:270px){
    .container{
        width: 65%;
    }
}

@media screen and (min-width:380px){
    .container{
        width: 60%;
    }
}

@media screen and (min-width:414px){
    .container{
        width: 55%;
    }
}

@media screen and (min-width:448px){
    .container{
        width: 45%;
    }
}

@media screen and (min-width:540px){
    .container{
        width: 40%;
    }
}

@media screen and (min-width:1024px){
    .container{
        max-height: 350px;
    }
}