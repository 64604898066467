.timelineCnt{
    /* border-right: 1px solid lightgray; */
    width: auto;
    min-width: 200px;
    max-width: 400px;
} 
.timelineCnt *{
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
}
.tItem{
    /* background-color: green !important; */
    display: flex;
    align-items: flex-start;
}
.tPara{
    cursor: pointer;
    margin: 10px 5px 10px 30px !important;
}
.lineActive{
    color: black;
    font-size: 14px;
    opacity: .5;
    line-height: 18px;
}
.lineErr{
    color: black;
    font-size: 14px;
    opacity: .5;
    line-height: 18px;
}
.lineCurrent{
    color: black;
    font-size: 19px;
    line-height: 24px;
    position: relative;
    margin: 13px 5px 13px 30px !important;
}
.lineInactive{
    color: black;
    font-size: 14px;
    opacity: .5;
    line-height: 18px;
}
.lineCurrent::before{
    content: '';
    width: 20.5px;
    height: 20.5px;
    border-radius: 50px;
    box-sizing: border-box;
    position: absolute;
    left: -14%;
    top: 0;
    border: 4px solid black;
    border-bottom-color: transparent;
    border-right-color: transparent;
    transform: rotate(-45deg);
    display: none;

}